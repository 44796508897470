
body {
  overflow-x: hidden;
}


.left-sidebar {
  border-radius: 7px;
}

.left-sidebar .list-group {
  box-shadow: unset;
  padding: 0;
  margin-bottom: 0;
}


.left-sidebar .list-group-item {
  padding: 0px;
  text-align: left;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 12px;
  line-height: 24px;
}

.left-sidebar .list-group-item li {
  transition: all 0.5s;
  cursor: pointer;
  font-size: 7px;
}

.left-sidebar .list-group-item a {
  color: #000 !important;
  text-decoration: none;
}

.left-sidebar .list-group-item li:hover {
  background-color: #fff;
  /*border-left: 3px solid #101010;*/
  padding-left: 0px;
  cursor: pointer;
  border-radius: 2px;
}

.left-sidebar .list-group-item .content {
  transition: all 0.5s;
}

.left-sidebar .list-group-item .content.up {
  display: none;
}

.left-sidebar .list-group-item .title {
  transition: all 0.5s;
  border-radius: 6px;
  cursor: pointer;
  display: block;
}

.left-sidebar .list-group-item .title:hover, .left-sidebar .list-group-item .title.selected {
  background-color: #fff;
}


.left-sidebar .list-group-item .title .toggle-list {
  float: right;
}

.left-sidebar .list-group-item .title .toggle-list i {
  font-size: 13px;
  position: relative;
  right: 10px;
  vertical-align: bottom;
}

.left-sidebar .sports-list img {
  width: 15px;
  vertical-align: middle;
}

.sports-list .content {
  margin-left: 8px;
}

.navbar {
  border-radius: 0px;
  /*background-image: url("/bg.png");*/
  min-height: 100px;
}


.slick-dots {
  bottom: 0 !important;
}

.slick-dots li {
  margin: 0 10px !important;
}

.slick-dots li button:before {
  width: 30px !important;
  content: "" !important;
  background-color: #eee !important;
  height: 3px !important;
  margin: 0 11px !important;
}

.slick-prev {
  z-index: 9999 !important;
  left: 12px !important;
}

.slick-next {
  z-index: 9999 !important;
  right: 12px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.upcoming-event {
  background-color: #f2f2f2;
  padding: 10px;
  box-shadow: 2px 2px 2px 1px #eee;
  margin: 5px 0;
  border-radius: 5px;
  min-height: 100px;
  cursor: pointer;
  transition: all 0.5s;
}

.upcoming-event:hover {

  background-color: #dcdcdc;
}

.upcoming-event .title {
  text-align: center;
  line-height: 20px;
}

.upcoming-event .team {
  text-align: center;
}

.upcoming-event .team img {
  width: 40px !important;
  margin: auto;
}

.upcoming-event .team h6 {
  font-size: 10px !important;
}

.upcoming-event .score {
  position: relative;
  top: 15px;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  color: #f39c12;
}

.nav-tabs > li, .nav-pills > li {
  float: none;
  display: inline-block;
  *display: inline; /* ie7 fix */
  zoom: 1; /* hasLayout ie7 trigger */
}

#sv-tabs .nav-tabs, .nav-pills, #statistics .nav-tabs, .nav-pills {
  text-align: center;
}

.upcoming-event a {
  color: #333;
  text-decoration: none
}

.hvr-underline-reveal {
  display: block !important;
}

.sv-box {
  background-color: #f2f2f2;
  border-radius: 15px;
  padding: 5px;
  box-shadow: 0 3px 3px 0 #f3eded;
  margin-top: 16px;
  display: block;
  position: relative;
}

.sv-box .header {
  display: inline-block;
  cursor: pointer;
  padding: 8px;
}

.sv-box .header .logo, .sv-box .header .title {
  display: inline-block;
}

.sv-box .header .logo img {
  width: 45px;
}

.sv-box a > .header {
  color: #101010;
}


.sv-box .header .title {
  padding-left: 20px;
  font-weight: 500;
  font-size: 14px;
  vertical-align: middle;
  color: #00222E;
}

.rsbc-switch-button.rsbc-switch-button-flat-round input[type=checkbox]:checked + label, .rsbc-switch-button.rsbc-switch-button-flat-round input[type=checkbox]:checked + label:after {
  background-color: var(--main-color);
}

.sv-box .header .title .name {
  display: block;
  font-weight: 400;
  font-size: 13px;
}

.sv-box .body {
  padding: 2px 10px;
}

.competitions {
  list-style: none;
  padding: 0;
  margin: 0;
}

.competitions li {
  background-color: #fff;

}

.competitions li:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.competitions li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.competitions .competition {
  display: block;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease;
  line-height: 16px;
  padding: 0px 6px 3px;
  border-top: dashed 1px #eee;

}

.competitions li:first-child .competition {
  border-top: dashed 0px #eee;

}

.competition-cell-table {
  display: table;
  width: 100%;
  table-layout: fixed;
  color: #00222E;
  white-space: nowrap;
  padding: 5px 0;
}

.competition-cell-table-cell {
  display: table-cell;
  padding: 0;
  margin: 0;
  table-layout: fixed;
  vertical-align: middle;
  text-overflow: ellipsis;
  position: relative;
  overflow: hidden;
}

.competition-cell-table-cell .name {
  vertical-align: sub;
}

.competition-cell-table-cell .logo img {
  width: 30px !important;
}

.competition-cell-side1 {
  text-align: right;
  padding-left: 10px;
  padding-right: 1px;
}

.competition-cell-side2 {
  text-align: left;
  padding-left: 1px;
}

.competition-cell-score {
  width: 95px;

  text-align: center;
  color: #00222E;
  font-size: 20px;
  letter-spacing: -1px;
  font-weight: 700;
  vertical-align: middle;
  margin: 0 10px;
}

.competition-cell-status {
  display: block;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
  color: #777;
  text-transform: uppercase;
  letter-spacing: -.02em;
  margin-top: 8px;
}

.competition-cell-status-name {
  position: absolute;
  right: 78px;
  top: 23%;
  color: #716f6f;
}

.competition-cell-score i {
  color: #777;
  font-size: 13px;
}

.competition-cell-time {
  color: var(--main-color);
  font-size: 13px;
  font-weight: bold;
}

.sticky {
  top: 10px !important;
  transition: all 0.5s;
}

.competition:hover {
  background-color: #efefef;
}

.calendar-matches {
  list-style: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 0;
  white-space: nowrap;
  margin: 0;
}

.calendar-matches li {
  display: inline-block;
  width: 13%;
  padding: 3px;
  margin: 3px;
  text-align: center;
  border-radius: 70px;
  background-color: #fff;
  line-height: 15px;
  cursor: pointer;
  transition: all 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.calendar-matches li.today {
  transition: all 0.5s;

}

.calendar-matches li.today.selected {
  background-color: #00222E;
  transition: all 0.5s;

}

.calendar-matches li.selected {
  background-color: #00222e !important;
  color: var(--main-color);
  transition: all 0.5s;


}

.calendar-matches li .day {
  font-weight: bold;
  font-size: 14px;
  display: block;
  text-align: center;
  line-height: 34px;
}

.calendar-matches li .name {
  font-size: 11px;
}

.calendar-day-names li {
  flex: 1;
  text-align: center;
  font-size: 0.7em;
  color: #959595;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 22px;
  background-color: transparent;
}

.calendar-matches li.empty {
  background-color: transparent !important;
}

#calendar {
  display: none;
  transition: all 0.5s;
}

#calendar.show {
  display: block;
}

#mini-calendar {
  transition: all 0.5s;

}

#mini-calendar.hide {
  display: none;
}

.calendar-icon {
  width: 4%;
  line-height: 33px;
  vertical-align: middle;
  background-color: #aaa !important;
  position: relative;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;

}

#calendar .calendar-icon {
  float: unset;
  margin-left: auto;
  width: 37px;

}

#mini-calendar .calendar-icon {
  width: 4.5%;
  line-height: 28px;
  top: -17px;
}

#mini-calendar li {
  width: 10%;
}

.calendar-icon i {
  color: #fff;
  font-size: 15px;
}

.toggle-slide {
  position: absolute;
  z-index: 9999;
  top: 43%;
  margin: 5px;
}

.toggle-slide.previous-slide {
  left: 0;
}

.toggle-slide.next-slide {
  right: 0;
}

.upcoming-events {
  position: relative;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 400px;
}

.main-slide {
  position: relative;
  overflow: hidden;
}

.main-slide .title {
  position: absolute;
  background-color: #2e74ff;
  color: #fff;
  padding: 10px;
  bottom: 31px;
  margin: auto 87px;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 3px;

}

.top-tournament {
}

.top-tournament img {
  width: 45px;
  margin: 10px 0;
}

#loading {
  text-align: center;
}

.match-view-headT {
  display: table;
  table-layout: fixed;
  width: 95%;
  margin: auto;
}

.match-view-head-side1, .match-view-head-side2 {
  font-size: 1.3em;
  font-weight: 900;
  text-transform: uppercase;
  vertical-align: top;
  line-height: 20px;
  padding-top:  5px;
  letter-spacing: -0.7px;
  word-wrap: break-word;
  text-align: center;
  position: relative;
}

.match-view-head-side1, .match-view-head-side2, .match-view-headSS {
  display: table-cell;
}

.event-team img {
  width: 85px;
}

.event-team h5 {
  font-weight: 800;
  color: #00222E;

}

.event-team a {
  text-decoration: none;
}

.match-view-headSS {
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
  line-height: 30px;
}

.match-view-headSS .score {
  min-width: 36px;
  color: white;
  background-color: var(--main-color);
  border-radius: 10px;
  display: inline-block;
  padding: 5px 0;
  min-height: 42px;
  vertical-align: middle;
  margin: 0 18px;
  font-size: 26px;
  text-align: center;
}

.event-status {
  margin: 10px 0;
  font-size: 16px;
  color: #00222E;
}

.match-view-headSS .start-time {
  font-size: 2em;
  line-height: .6em;
  font-weight: 300;
  color: #888;
  border-color: #eee;
  letter-spacing: 0;
}

.days-left {
  font-size: .8em;
  color: #c74a58;
  font-weight: bold;
  margin-bottom: 8px;
}

.goal-incidents {
  padding: 0;
  list-style: none;
}

.goal-incidents li {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #3a0115;
  position: relative;
  font-weight: 400;
  vertical-align: middle;
  line-height: 15px;
  min-height: 15px;
  font-size: 1.1em;
  cursor: pointer;
  margin-top: 6px;
}

.side1 {
  text-align: right;
  margin-right: 50%;
  padding-right: 46px;
}

.side2 {
  text-align: left;
  margin-left: 50%;
  padding-left: 46px;
}

.sidestatus {
  position: absolute;
  top: 3px;
  width: 60px;
  text-align: center;
  color: #3a0115;
  font-weight: 400;
  line-height: 20px;
  right: -24px;

}

.side2 .sidestatus {
  left: -35px;
}

.goal-incidents li:after {
  content: "\f1e3";
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: top;
  top: 6px;
  position: absolute;
  font-family: "Font Awesome 5 Free";
  right: 22px;

}

.side2:after {
  left: 19px;
}




#main-search-input {
  background-color: #f2f2f2;
  padding: 10px;
  border: 0px;
  box-shadow: unset;
  height: 45px;
  border-radius: 100px;
}

#main-search {
  position: relative;
  z-index: 99999;

}

#main-search i.fa-search {
  position: absolute;
  z-index: 99999999999999999;
  right: 15px;
  top: 13px;
  font-size: 17px;
  color: #999;
}

#main-search #result {
  position: absolute;
  z-index: 99999;
  width: 100%;
  background-color: #f2f2f2;
  padding: 10px;
  top: 49px;
  border-bottom: 1px solid #eee;

  border-radius: 5px;
}

#search-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: red;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 99999;
  display: none;
}

.title-dashed span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 12px 0;
  margin: 8px 0 0;
  text-transform: uppercase;
  font-size: 15px;
  width: 100%;
  font-weight: 500;
}

.title-dashed span:before {
  content: "";
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 1px;
  top: 10px;
  background-color: rgba(0, 12, 36, .07);
  margin-right: 12px;

}

.title-dashed span:after {
  content: "";
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 1px;
  top: 10px;
  background-color: rgba(0, 12, 36, .07);
  margin-left: 12px;
}


#main-search #result ul {
  padding: 0;

}

.highlights {
  background-color: #121212;
  padding: 10px;
  border-radius: 5px;
}

.highlight-info {
  cursor: pointer;

}

.highlight-info img.selected {
  border: 3px solid #eee;
  opacity: 1;
}

.highlight-info img {
  width: 89px !important;
  height: 85px;
  border-radius: 100px;
  border: 3px solid #525252;
  margin: auto;
  opacity: 0.7;

}

.highlight-info h5 {
  color: #eee;
  font-size: 10px;
  text-align: center;
}

#highlight-items {
  margin-top: 10px;
}

#loading-highlight {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

#tournament-info {
  margin-top: 5px;
  padding: 5px;
}

#tournament-info .category {

  margin: 0;
  font-weight: 400;
}

#tournament-info .name {
  margin: 0;
  font-weight: 500;
  font-size: 36px;
  text-transform: uppercase;
  line-height: 40px;
  color: #101010;
}

#tournament-info .description {
  font-weight: 400;
  margin: 0;
}

.standings th {
  color: #101010;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}

.standings th:nth-child(1), .standings th:nth-child(2) {
  width: 5%;
}

.standings th:nth-child(3) {
  width: 55%;
}

.standings td {
  text-align: center;
}

.standings td:nth-child(2) img {
  width: 25px;
  border-radius: 100px;
}

.standings td:nth-child(3) {
  text-align: left;
}

.competitions .competition.tournament a {
  color: #101010;
}

.competitions .competition.tournament h3 {
  font-weight: 500;
}



.lineup {
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

#lineups-tab li {
  width: 50%;
}

#lineups-tab li a {
  text-align: center;
  color: #22343c;
}


.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
  background-color: unset !important;
  background-image: unset !important;
}

.tournament-navbar {
  display: inline-block;
  text-align: center;
  min-width: 80px;
}

.tournament-navbar img {
  width: 40px;
}

.tournament-navbar h6 {
  padding: 0;
  margin: 0;
  color: #000;
}


.tournaments-navbar {
  padding: 0;
  text-align: center;
  z-index: 9999999999999;
}

.navbar-nav > li > .dropdown-menu {
  z-index: 999999;
}

footer {
  flex: 0 0 auto;
  display: flex;
  color: #ccc;
  margin: 1em;
  padding: 1em;
  width: 100%;
  justify-content: center;
  margin-bottom: 0;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.tournament-team {
  text-align: center;
}

.navbar-brand {
  padding: 7px 19px;
  margin: 0;
}

.tournament-team img {
  max-width: 56px;
  text-align: center;
  margin: auto auto 5px;
}

#soccer-nav-dropdown, #others-nav-dropdown {
  font-family: "Font Awesome 5 Free", sans-serif;
}

#soccer-nav-dropdown:before, #others-nav-dropdown:before {
  font-family: "Font Awesome 5 Free", sans-serif;
  color: #f3d008;
  vertical-align: middle;
  padding-right: 4px;
}

#soccer-nav-dropdown:before {
  content: '';
}

#others-nav-dropdown:before {
  content: '';
}

.substitution {
  padding: 0 20px;
  margin: 10px;
  list-style: none;
}

.substitution li {
  line-height: 30px;
  margin-bottom: 10px;
  padding: 7px;
  width: 100% !important;
  border-radius: 10px;
}

.substitution li .name {
  min-width: 35%;
  display: inline-block;
}

.substitution li:hover {
  cursor: pointer;
}


.substitution li:nth-child(odd) {
  background-color: #fff;
}

.substitution li:nth-child(even) {
  background-color: #ebebeb;
}

.sticky {
  top: 0 !important;
  transition: height 1s ease;
}

#event-tab {
  opacity: 0;
  transition: all 0.4s ease;
  display: block;
  width: 100%;
  box-sizing: border-box;
  visibility: hidden;
  will-change: opacity, visibility;
  height: 0px;
}

#event-tab.show {
  opacity: 1;
  visibility: visible;
  height: auto;

}

.match-view-head-side1.small, .match-view-head-side2.small {
  padding: 0;
}

.small .event-team img {
  width: 50px;
}

.small .event-team h4 {
  font-size: 14px;
}

#event-tab .sv-box .body {
  padding: 0;
}

.match-view-headSS.small .event-status {
  margin: 0;
}

.sv-box.no-radius {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: unset;
}

#event-sticky-info .nav.nav-pills {
  padding-top: 5px;
  padding-bottom: 2px;
  background-color: #f2f2f2;
  box-shadow: 0 3px 3px rgba(175, 175, 175, 0.2);
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  position: relative;
  overflow: hidden;
}

#event-sticky-info .nav-tabs > li, #event-sticky-info .nav-pills > li {
  flex: 1;
}

#event-sticky-info .nav-pills > li.active > a, #event-sticky-info .nav-pills > li.active > a:focus, #event-sticky-info .nav-pills > li.active > a:hover {
  background-color: unset;
  border-bottom: 4px solid var(--main-color);
  color: var(--main-color) !important;
  font-weight: bold;
}

#event-sticky-info .nav-pills > li > a {
  color: #00222E;
  display: block;
  text-decoration: none;
  padding: 5px;
}

#channels td, .streams td {
  color: #00222E;

}

.streams td {
  padding: 3px !important;
}

.streams td div {
  line-height: 10px;
}

.streams td i {
  font-size: 20px;
  transition: all 0.5s;
}

.streams td i:hover {
  color: var(--main-color);
  cursor: pointer;
}

.badge {
  background-color: var(--main-color);
  color: #00222E;
}

.sv-hidden {
  visibility: hidden;
  opacity: 0;
  height: 0px !important;
  transition: all 0.2s ease;

}

.sv-block {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease;

}

.footer-teams ul {
  text-align: center;
  line-height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-teams li {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  line-height: 35px;
}

.footer-teams img {
  width: 20px;
  vertical-align: sub !important;
}

.footer-teams a {
  color: var(--main-color);
  margin: 0 5px;
  border: 1px solid var(--main-color);
  padding: 5px;
  border-radius: 5px;
}

.footer-teams .name {
  padding-left: 5px;
}

.footer-teams {
  text-align: center;
}

footer {
  bottom: 0;
}


footer hr {
  color: lightgray;
  background-image: -webkit-linear-gradient(left, #112241, gray, #112241);
  background-image: -moz-linear-gradient(left, #112241, gray, #112241);
  background-image: -ms-linear-gradient(left, #112241, gray, #112241);
  background-image: -o-linear-gradient(left, #112241, gray, #112241);
  border: 0;
  border-top: 1px solid rgba(243, 243, 243, 0.1);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.timer {
  color: #000;
}

.footer-teams {
  padding: 0;
}

tr.player img {
  width: 42px;
  border-radius: 100px;
  text-align: center;
}

tr.player strong {
  display: block !important;
}

tr.player small {
  color: #7e7e7e;
}

#highlight-tabs .nav-tabs > li, #highlight-tabs .nav-pills > li {
  display: block;
}

#highlight-tabs .nav-pills > li > a {
  padding: 9px 2px !important;
  color: #d3d4d4;
  white-space: nowrap;
  background-color: #101c25;
  font-size: 12px;

}

#highlight-tabs .nav-pills > li.active > a, #highlight-tabs .nav-pills > li.active > a:focus, #highlight-tabs .nav-pills > li.active > a:hover {
  background-color: #213f4f;
}

#highlight-tabs .nav-pills > li > a:hover {
  background-color: #1d3643;

}

#live-btn {
  background-color: #fff;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  padding: 0px;
  text-transform: uppercase;
  color: #c1272d;
  font-weight: bold;
  margin-top: 1px;
  cursor: pointer;
}


#live-btn:before {
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  width: 6px;
  height: 6px;
  right: 10px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: #ed1c24;
  -webkit-animation: pulse 1s ease-in infinite;
  animation: pulse 1s ease-in infinite;
}

.blink {

}

@keyframes pulse {
  0% {
    background-color: rgba(255, 65, 54, 0.19);
  }
  100% {
    background-color: #FF4136;
  }
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(255, 65, 54, 0.19);
  }
  100% {
    background-color: #FF4136;
  }
}

#owl-next, #owl-prev, .owl-next, .owl-prev {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px 12px;
  background-color: rgba(0, 0, 0, 0.63);
  z-index: 999999;
  height: 100%;
  line-height: 100px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: 33px;
  cursor: pointer;
}

#owl-prev {
  left: 0 !important;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.owl-prev {
  left: 0;
  bottom: 0%;
  height: 100%;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.owl-next {
  bottom: 0;
  height: 100%;
}

.owl- {
}

.owl-next i, .owl-prev i {
  color: #fff;
}

#owl-next i, #owl-prev i, .owl-next i, .owl-prev i {
  text-align: center;
  font-size: 15px;
  position: absolute;
  top: 42%;

}

.highlight-items {
  position: relative;
  overflow: hidden;

}

.player {
  text-align: center;
  padding: 10px 0px;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  min-height: 145px;
}

.player img {
  border-radius: 100px;
  width: 85px;
}


.highlight-team {
  background-color: #ced1d5 !important;
}


.main-slide img {
  object-fit: cover;
}

.main-slide, .left-sidebar .list-group-item a.event-slide {
  border-radius: 15px;
}


.event-slide .team {
  position: absolute;
  bottom: 20%;
}

.event-slide .team img {
  width: 95px;
}

.event-slide .home-team {
  left: 20%;
}

.event-slide .away-team {
  right: 20%;
}

.search h4, .search h4 {
  color: #000;
}


#footer-links {
  text-align: right;
  padding: 0;
  margin: 0;
}

#footer-links li {
  display: inline-block;
  list-style: none;
  margin: 0 4px;
}

#footer-links li a {
  color: #a3a3a3;
}

#cookie-notice {
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 15px;
  width: 100%;
  padding: 10px;
  color: #fff;
  z-index: 99999999999;
}

#footer-socials {
  text-align: left;
}

#footer-socials li {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
}

#footer-socials li a {
  color: #a3a3a3;
}


#showMore {
  position: absolute;
  bottom: 15px;
  right: 0;
  background-color: #fff;
  color: #000;
  padding: 5px 10px;
  text-align: center;
  width: 100%;
  border-bottom: 2px solid #d7d7d7;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;

}


#related-channels {
  padding: 0;
  margin: 0;
}

#related-channels li {
  list-style: none;
  padding: 10px 10px;
}

#related-channels li:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

#related-channels li:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

#related-channels li a {
  display: flex;
  color: #000;
}

#related-channels li a img {
  width: 65px;
  border-radius: 100px;
}

#related-channels li a .logo {
  flex: 0.1;
}

#related-channels li a .name {
  flex: 0.7;
  line-height: 45px;
}

#related-channels li:nth-child(odd) {
  background-color: #fff;
}


#channels tr:nth-child(odd) {
  background-color: #fff;
}

#channels tr td:first-child {
  width: 30%;

}

#channels tr td:last-child a {
  color: #000;
}


.embed-code {
  display: flex;
  height: 40px;
  border-radius: 20px;
  background: #fff;
}

.embed-code-icon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #717171;
}

.embed-code-tw {
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}

.embed-code-button {
  background-color: #3e768e;
  cursor: pointer;
  display: inline-block;
  line-height: 40px;
  border-radius: 20px;
  width: 80px;
  text-align: center;
  color: #fff;
  transition: background-color .2s ease-in-out;
  -webkit-transition: background-color .2s ease-in-out;
  position: relative;
  font-weight: 700;
}

.embed-code-icon:after {
  position: absolute;
  top: 8px;
  left: 10px;
  width: 20px;
  height: 23px;
  background-image: url(https://d37kf7rs4g1hyv.cloudfront.net/scorebat/spritenobm-min.png);
  background-size: 146px;
  background-repeat: no-repeat;
  background-position: 0 -33px;
  content: "";
  background-position: -119px -184px;
}

.embed-code textarea {
  resize: none;
  background: transparent;
  border: none;
  color: #4f4f4f;
  display: block;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  line-height: 36px;
  height: 40px;
  width: 100%;
}


#embed-description {
  padding: 10px;
  color: #3e768e;
}


.navbar-brand {
  display: flex;
  align-items: center;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.left-sidebar-mobile {
  display: none;
  position: fixed;
  right: inherit;
  z-index: 999999100;
  height: 100%;
  transition: all 0.5s ease 0s;
  transform: translate3d(-106%, 0px, 0px);
  top: 0;
  border-radius: 0px;
  overflow: scroll;
  width: 250px;
}

.left-sidebar-mobile.open {
  transform: translate3d(-6%, 0px, 0px);
}

.sticky {
  z-index: 9999122 !important;
}

#right-side-bar {
  position: fixed;
  background-color: #f2f2f2;
  padding: 10px;
  width: 300px;
  top: 0;
  z-index: 999999100;
  height: 100%;
  transition: all 0.5s ease 0s;
  overflow: scroll;
  right: -100%;

}

#right-side-bar.open {
  right: 0;
}


.tournaments-navbar-vertical {
  list-style: none;
  padding: 10px;
}

.tournament-navbar-vertical {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.tournament-navbar-vertical h6 {
  color: #101010;
  display: inline-block;
  margin-left: 10px;
}

.tournament-navbar-vertical img {
  display: inline-block;
  width: 50px;
}


#logo-layer {
  position: absolute;
  right: 0;
  margin: 10px;
  z-index: 999999999;
}

#logo-layer img {
  width: 100px;
}

.embed .highlights {
  background-color: #f2f2f2;
  padding: 2px;
}

.embed .highlight-items {
  background-color: #ececec;
  padding-top: 5px;
}

.embed .highlight-info h5 {
  color: unset;
}

.embed .highlight-info img.selected {
  border-color: #f5338a;
}

.embed #owl-next, .embed #owl-prev {
  background-color: rgba(150, 150, 150, 0.63);
}

.embed #highlight-tabs .nav-pills > li {
  margin-bottom: 2px;
}

.embed #highlight-tabs .nav-pills > li > a {
  height: 60px;
  border-radius: 100px;
  width: 61px;
  margin: auto;
  background-color: #fff;
  color: #000;
  font-size: 10px;
  line-height: 23px;
}

.embed .nav > li > a > img {
  width: 20px;
  display: block;
  margin: auto;
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.embed #highlight-tabs .nav-pills > li.active > a, .embed #highlight-tabs .nav-pills > li.active > a:focus, .embed #highlight-tabs .nav-pills > li.active > a:hover {
  background-color: #0f242c;
  color: #fff;
}

.embed #title {
  text-align: center;
  background-color: #e5c508;
  color: #000;
  padding: 10px;
  margin: 0;
}

.embed #title a {
  color: #000;
}

.other-match {
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.other-match .tournament {
  width: 50px !important;
  margin: auto;
  display: block;
}

.other-match .team-logo {
  display: inline-block;
  width: 50%;
  margin-top: -35px;
}

.other-match .team-logo h5 {
  font-size: 12px;
}

.other-match .team-logo img {
  width: 60px;
  margin: auto;
}

.other-match span {
  font-size: 19px;
  margin: 0 5px;
}

.other-match .score {
  padding: 5px 10px;
  background-color: #181818;
  color: #fff;
  font-size: 16px;
  display: inline-block;
  min-width: 30px;
  border-radius: 10px;
}

.embed .tab-content, .embed .tab-content > .active {
  height: 100%;
}

.embed .highlight-items {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.embed .close-ad-button {
  float: right;
  border-radius: 0px;
  right: 99px;
  position: relative;
  top: 51px;
  z-index: 9999999999;
}

@media (max-width: 768px) {
  .other-match .team-logo img {
    width: 40px;
    margin: auto;
  }

  .other-match .tournament {
    width: 40px !important;
    margin: auto;
    display: block;
  }
}

.embed .highlight-info img {
  width: 100px !important;
  height: 100%;
}

.embed .event-team img {
  width: 28% !important;
  margin: auto;
}

.embed .event-team h4 {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.embed .match-view-headSS .score {
  font-size: 13px;
  min-width: 28px;
  margin: 0 3px 4px;
  min-height: 0 !important;
  height: 32px;
  padding: 0;
}

.embed .sv-box .header .title {
  vertical-align: top;
}

.embed .sv-box .header {
  padding-bottom: 0 !important;
}

.embed .event-status {
  color: #333;
  margin: 0;
  font-size: 12px;
}

.embed a {
  text-decoration: none;
}

.embed .tournaments {
  padding: 3px;
  position: relative;
  margin-right: 65px;
}

.embed .tournaments .list-group {
  position: absolute;
  z-index: 99999999999;
  max-height: 300px;
  overflow-y: scroll;
  display: none;
  bottom: 25px;
}

#uncontrolled-tab-example .nav-tabs {
  border-bottom: 0;
}

#uncontrolled-tab-example .nav-tabs > li, #uncontrolled-tab-example .nav-pills > li {
  margin-top: 3px;

}

.embed .tournaments .list-group.open {
  display: block;
}

.embed .tournaments .list-group-item {
  width: 200px;
}

.embed .tournaments .list-group img {
  width: 30px;
}

.embed .tournaments .list-group-item:hover {
  background-color: #eee;
  cursor: pointer;
}

.selected-tournament {
  cursor: pointer;
  background-color: #eee;
  padding: 2px;
  border-radius: 8px;
  margin: 0;
}

.selected-tournament span {
  font-size: 10px;
  padding-left: 2px;
}

.video-type-icons {
  list-style: none;
  margin: auto;
  text-align: center;
  padding: 0;
}

.video-type-icons .video-type {
  display: inline-block;
  vertical-align: middle;
  position: relative;

}

.video-type-icons div {
  background-color: #fff;
  padding: 7px;
  padding-top: 10px;
  color: #000;
  width: 65px;
  margin: 0 5px;
  height: 60px;
  border-radius: 5px;
  font-size: 10px;
}

.video-type-icons li img {
  width: 24px !important;
  margin: auto;

}

.embed .videos {
  margin: 10px;
  position: absolute;
  min-width: 200px;
  z-index: 99999999;
  bottom: 60px;
  max-height: 145px;
  overflow-y: scroll;
  font-size: 11px;
  color: #000;
  box-shadow: 2px 1px 2px 1px #cfcfcf;
  display: none;
  left: -90%;
}

.embed .videos.open {
  display: block;
}

.embed .videos li {
  padding: 7px 5px;
  min-height: 45px;
  text-align: left;
  transition: all;
}

.embed .videos li:hover {
  background-color: #0f242c;
  color: #fff;
}

.embed .videos img {
  width: 30px !important;
  display: inline-block;
  float: left;
}

.embed .videos li span:nth-child(2) {
  vertical-align: sub;
  text-align: left;
  line-height: 30px;
  padding-left: 5px;
}

.embed .sv-box, .embed .sv-box .body {
  padding: 0;

}

.embed .sv-box .body {
  padding-bottom: 5px;
}

.embed .sv-box .header .logo img {
  width: 40px;
}

.navbar-brand > img {
  height: 30px;

}

.previous-week {
  background-color: #aaaaaa;
  color: #fff;
  padding: 7px;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 5px;
}


.embed .nav-tabs > li > a, .embed .nav-tabs > li > a:hover {
  padding: 0;
  border: 0;
  font-size: 11px;
  padding-left: 2px;
  line-height: 30px;
}

.sv-box .right-side {
  margin-right: 10px;
  margin-top: 7px;
  position: absolute;
  right: 2%;
  z-index: 9999999
}

.center-side {
  position: absolute;
  top: 10%;
  right: 0;
  left: 40%;
}

.ad-button, .ad-button:hover {
  text-transform: uppercase;
  background-color: var(--main-color) !important;
  color: #000 !important;
  padding: 12px 0;
  font-size: 20px;
  text-decoration: none;
  margin: 0 5px;
  vertical-align: middle;
}

.voted {
  color: var(--main-color)
}

.streams td {
  vertical-align: middle !important;
}

#top-leagues-header {
  list-style: none;
  margin: 0 auto;
  text-align: center;
  padding: 0;
}

#top-leagues-header li {
  display: inline-block;
  padding: 5px 4px;
}

#top-leagues-header li img {
  width: 50px;
}

td {
  padding: 3px;
}

.btn-ss {
  background-color: var(--main-color) !important;
  color: #fff ;
}

.btn-sv {
  background-color: #fcc91c !important;
  color: #00222e !important;
}

.top-tournament .league-img {
  vertical-align: middle !important;
}

.top-tournament .league-name {
  vertical-align: sub !important;
  color: #00222E  ;
  padding-left: 3px;
  font-size: 18px;
}

.top-tournament a {
  text-decoration: none !important;
}

body {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.main {
  min-height: calc(100vh - 280px);
}

.navbar-description {
  color: var(--main-color);
  font-family: 'Lobster Two', Helvetica, sans-serif;
  font-size: 17px;
  font-style: italic;
}

#event-tags {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  font-size: 14px;
}

#event-tags a {
  color: #000000;
}

.ad-button-small, .ad-button-small:hover {
  padding: 8px;
  font-size: 12px;
}

.btn_call {
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 21px;
  font-weight: 500;
  padding: 14px 25px;
  background-color: #1E62D0;
  box-shadow: 1px 1px 6px 0px #000000;
  -webkit-box-shadow: 1px 1px 6px 0px #000000;
  -moz-box-shadow: 1px 1px 6px 0px #000000;
  text-shadow: 1px 1px 2px #00000060;
  border: solid #1E62D0 1px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.btn_call:hover {
  color: #fff !important;
  background: #1E62D0;
  background-image: -webkit-linear-gradient(top, #1E62D0, #3D94F6);
  background-image: -moz-linear-gradient(top, #1E62D0, #3D94F6);
  background-image: -ms-linear-gradient(top, #1E62D0, #3D94F6);
  background-image: -o-linear-gradient(top, #1E62D0, #3D94F6);
  background-image: linear-gradient(to bottom, #1E62D0, #3D94F6);
  text-decoration: none;
}

.btn_FontFix {
  font-size: 17px;
}

@media only screen and (max-width: 768px) {
  .hdbutt {
    text-align: center !important;
  }
}

.tournament-button {
  padding: 5px;
  /*border: 1px solid #fcc91c;*/
  color: #00222e !important;
  border-radius: 5px
}

#ad-button {
  margin-left: 7px;
  vertical-align: sub;
  background: #e60000;
  color: #fff;
  font-size: 90%;
  FONT-WEIGHT: 600;
  padding: 5px 8px;

  border-radius: 4px;
}

#header-teams {
  white-space: nowrap !important;
  text-align: center;
}

#header-teams .team {
  display: inline-block;
}

#header-teams .team img {
  max-width: 100%;
  width: 50px;
  margin-right: 5px;
}

.preview-btn {
  background-color: var(--main-color);
  color: #fff;
  border-color: var(--main-color);;
}

.title-event .competition-cell-table {
  padding-right: 75px;
}
.title-event .competition-cell-table .competition-cell-side1{
  text-align: left;
}


.title-event .competition-cell-status-name {
  top: 5%;
}

/*bold italic version*/
@font-face {
  font-family: 'Dyno Sans';
  src: url('/fonts/dyno bold italic.ttf');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/* latin */
@font-face {
  font-family: 'Lobster Two';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Lobster-Two.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
#logo-type {
  font-family: "Dyno Sans", sans-serif !important;
  font-weight: bold;
  font-style: italic;
  margin: 0;
  padding-right: 5px;
}

.competition-cell-table .logo.home-team {
  padding-left: 3px;
  width: 35px !important;
}

.competition-cell-table .logo.home-team img {
  width: 30px !important;
  margin: 0;

}
#our-sites{
  display: flex;
}
#our-sites .btn{
  flex:1;
  margin: 0 5px;
  font-weight: bold;
  color: #fff;
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.btn-fad{
  background-color: #c80e2f;
  border-color:  #c80e2f;
  color: #fff;
}

.btn-fad:hover{
  color: #fff;
}



@media (max-width: 767.98px) {
  .event-team h5 {
    font-size: 20px;
  }
  #our-sites{
    flex-direction: column;
  }
  #our-sites .btn{
    margin-bottom: 5px;
  }
  .navbar-brand{
    white-space: normal;
  }
}
@media (max-width: 575.98px) {
  .event-team h5 {
    font-size: 15px;
  }
  #mini-calendar li{
    width: 31%;
  }
}


@font-face {font-family: "Apple SD Gothic Neo"; src: url("//db.onlinewebfonts.com/t/67680f2eb947c5cbd58b40961b4a61e9.eot"); src: url("//db.onlinewebfonts.com/t/67680f2eb947c5cbd58b40961b4a61e9.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/67680f2eb947c5cbd58b40961b4a61e9.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/67680f2eb947c5cbd58b40961b4a61e9.woff") format("woff"), url("//db.onlinewebfonts.com/t/67680f2eb947c5cbd58b40961b4a61e9.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/67680f2eb947c5cbd58b40961b4a61e9.svg#Apple SD Gothic Neo") format("svg"); }
.our-service-v1{
  background-color: #006495;
  color: #fff !important;
  border-radius: 8px;
  padding: 17px 45px;
  font-family: "Apple SD Gothic Neo";
  margin-top: 5px;
  text-decoration: none !important;
  display: block;
  &:hover{
    color: #fff !important;
  }
  .get-btn{
    font-family: "Apple SD Gothic Neo";
    background-color: #d02827;
    border: 1px solid #bac5e3;
    padding-right: 40px;
    font-weight: bold;
    padding-left: 40px;
  }

}

.competition-cell-status-name {
  right: 10px;
}
@media (max-width: 767px) {
  .ad-button{
    display: none;
  }
  .left-sidebar-mobile {
    display: block;
  }

  .main-slide .title {
    margin: auto 10px;
  }

  .event-slide .home-team {
    left: 5%;
  }

  .event-slide .away-team {
    right: 5%;
  }

  #main-search-input {
    padding-right: 37px;
  }

  .sv-box .header .title {
    padding-left: 0;
  }

  .event-team img {
    width: 55px;
  }

  .event-status {
    font-size: 12px;
  }

  .event-team h4 {
    font-size: 13px;
  }

  .match-view-headSS .score {
    min-width: 24px;
    font-size: 14px;
    margin: 0 5px;
  }

  #event-sticky-info .nav-pills > li > a, #event-sticky-info .nav-pills > li > a:focus, #event-sticky-info .nav-pills > li > a:hover {
    font-size: 10px;
    padding: 10px 0;
  }

  #event-sticky-info .nav-pills > li > a > span {
    display: block;
  }

  #tournament-info .name {
    text-align: center;
  }

  .calendar-matches li .name {
    font-size: 10px;
  }

  .calendar-matches li .day {
    font-size: 12px;
  }

  .goal-incidents li {
    font-size: 11px;
  }

  .goal-incidents li:after {
    width: 8px;
  }

  .side1 {
    padding-right: 38px;
  }

  .side2 {
    padding-left: 35px;
  }

  .highlight-info img {
    width: 65px !important;
    height: 60px;
  }

  #highlight-tabs .nav-pills > li > a {
    font-size: 11px;
  }

  .lineup {
    margin: 0;
    padding: 0;
  }

  .MatchViewPlayerOnFieldWithImage .MatchViewPlayerOnFieldName {
    font-size: 12px;
    line-height: unset;

  }

  .MatchViewPlayerOnFieldWithImage .MatchViewPlayerOnFieldNameWrapper {
    width: 80px;
    top: 32px;
    left: 53%;
  }

  .MatchViewPlayerOnFieldImage {
    width: 42px;
    height: 40px;
  }

  .MatchViewPlayerOnFieldNumber {
    font-size: 11px;
  }

  .MatchViewLineupsFormationVertical .MatchViewLineupsFormationHalf2 .MatchViewPlayerOnField {
    margin-right: 10px;
  }

  .lineup-sv {
    padding: 0 !important;
  }

  .MatchViewStatsFeaturedStatValue {
    font-size: 14px;
  }

  .MatchViewStatsFeaturedStatName {
    font-size: 14px;
  }

  .MatchViewStatName {
    font-size: 14px;
  }

  .MatchViewStatChartLabel {
    font-size: 15px;
  }

  .g-recaptcha {
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }

  #content {
    padding: 0;
  }

  #layout {
    margin: 0;
  }

  .competition-cell-status-name {
    display: none;
  }

  .brand-centered {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 67%;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
  }
  .left-sidebar-mobile.open {
    transform: translate3d(-6%, 0px, 0px);
  }
}

@media (min-width: 750px) {

  .brand-centered {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 63%;
    top: 0;
    margin: 0 auto;
  }

  .navbar-toggle-sidebar {
    display: block !important;
  }

  .left-sidebar-mobile {
    display: block;
    transform: translate3d(-150%, 0px, 0px);

  }

  .left-sidebar-mobile.open {
    transform: translate3d(-5%, 0px, 0px);
  }
}

@media (max-width: 992px) {
  .ad-button{
    display: none;
  }
}
@media (min-width: 780px) {

  .left-sidebar-mobile.open {
    transform: translate3d(-30%, 0px, 0px);
  }
}
@media (min-width: 780px) {
  .left-sidebar-mobile.open {
    transform: translate3d(-18%, 0px, 0px);
  }
}
@media (min-width: 815px) {
  .left-sidebar-mobile.open {
    transform: translate3d(-40%, 0px, 0px);
  }
}
@media (min-width: 830px) {

  .left-sidebar-mobile.open {
    transform: translate3d(-28%, 0px, 0px);
  }
}
@media (min-width: 968px) {

  .competition-cell-status-name {
    /*right: 27px;*/
  }
  .left-sidebar-mobile.open {
    transform: translate3d(-55%, 0px, 0px);
  }

}

@media (min-width: 992px) {
  .competition-cell-status-name {
    /*right: 37px;*/
  }
  .left-sidebar-mobile {
    display: none;
  }
  .navbar-toggle-sidebar {
    display: none !important;
  }
  .brand-centered{
    width: unset;
  }
}

@media (min-width: 1200px) {
  .highlight-ads{
    margin: auto;
    margin-top: 10px;
  }
  .competition-cell-status-name {
    /*right: 57px;*/
  }
  .left-sidebar-mobile {
    display: none;
  }
  .navbar-toggle-sidebar {
    display: none !important;
  }
  .brand-centered{
    width: unset;
  }

}


.brand-centered {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
}
@media (max-width: 1199px) {
  .competition-cell-status-name {
    /*right: 37px;*/
    display: none;
  }

}


.btn-discord{
  background-color: #7289da;
  border-color: #7289da;
  color: #fff !important;
}

.navbar-nav .nav-link{
  font-size: 14px;
  color: #fff;
}
.navbar-light .navbar-nav .nav-link{
  color: #fff;
}
