body.dark {
  background-color: #121212;

  .rsbc-switch-button {
    color: #fff;
  }

  .sv-box {
    box-shadow: unset;
    background-color: var(--box-bg-color);

    .header .title {
      color: #fff !important;
    }
  }

  .top-tournament .league-name, .competition-cell-table {
    color: #fff;
  }

  .navbar-nav .nav-link {
    color: #fff;

    &:hover {
      color: var(--main-color);
    }
  }

  .competitions li {
    background-color: #121212;

    .competition {
      border-color: var(--box-bg-color);

      &:hover {
        background-color: var(--box-bg-color) !important;
      }
    }
  }

  .calendar-matches li {
    background-color: #121212;
    color: #fff;
  }

  .competition-cell-score, .event-team h5, .event-status, #event-tags a, #event-tags {
    color: #fff;
  }


  #event-sticky-info .nav.nav-pills {
    background-color: #121212;
  }

  .streams-table-new {
    background-color: #121212;
    padding: 5px;

    tbody tr td {
      background-color: #212121;
      border: unset !important;
      color: #fff;
    }

    .username {
      color: #fff;
    }
  }

  #streams {
    color: #fff;
    .card, .streams-header {
      background-color: #121212;
    }
  }

  #tournament-info {
    .name {
      color: #fff;
    }

    .description {
      color: #fff;
    }
  }
  .navbar-light .navbar-toggler-icon{
    filter: brightness(0) invert(1);

  }
  .match-view-headT h5{
    color: #fff;
  }
  #standings-table{
    a{
      color: #fff;
    }
  }
}


body.light{
  .latest-match-item,#standings-table{
    color: #000;
    a{
      color: #000;
    }
  }
}