
.flags {
    display: inline-block;
    vertical-align: top
}

.flags--sm, .flags-sm {
    -webkit-transform: scale(.6);
    -ms-transform: scale(.6);
    transform: scale(.6);
    vertical-align: middle
}

.flags--xs {
    vertical-align: top;
    margin: -8px;
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    transform: scale(.5)
}

.flags-- {
    display: none
}

.flags--category, .flags--md, .flags-category, .flags-md {
    width: 32px;
    height: 32px;
    background: url("/images/flags-sprite.png") 0 0 no-repeat;
    background-position: 43.9299% 0
}

@media (-o-min-device-pixel-ratio: 3/2),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx) {
    .flags--category, .flags--md, .flags-category, .flags-md {
        background-image: url("/images/sprite-category-retina.png?asdsad");
        background-size: 7680px 32px
    }
}

.flags--kuwait, .flags--kwt {
    background-position: 0 0
}

.flags--afg, .flags--afghanistan {
    background-position: .41838% 0
}

.flags--alb, .flags--albania {
    background-position: .83676% 0
}

.flags--algeria, .flags--dza {
    background-position: 1.25514% 0
}

.flags--american-samoa, .flags--asm {
    background-position: 1.67352% 0
}

.flags--american-virgin-island, .flags--vir {
    background-position: 2.0919% 0
}

.flags--and, .flags--andorra {
    background-position: 2.51028% 0
}

.flags--ago, .flags--angola {
    background-position: 2.92866% 0
}

.flags--aia, .flags--anguilla {
    background-position: 3.34704% 0
}

.flags--antigua-and-barbuda, .flags--antigua-barbuda, .flags--atg {
    background-position: 3.76542% 0
}

.flags--arabic {
    background-position: 4.1838% 0
}

.flags--arg, .flags--argentina {
    background-position: 4.60218% 0
}

.flags--arm, .flags--armenia {
    background-position: 5.02056% 0
}

.flags--abw, .flags--aruba {
    background-position: 5.43894% 0
}

.flags--aus, .flags--australia {
    background-position: 5.85732% 0
}

.flags--austria, .flags--austria-amateur, .flags--aut {
    background-position: 6.2757% 0
}

.flags--aze, .flags--azerbaijan {
    background-position: 6.69408% 0
}

.flags--bahamas, .flags--bhs {
    background-position: 7.11246% 0
}

.flags--bahrain, .flags--bhr {
    background-position: 7.53084% 0
}

.flags--bangladesh, .flags--bgd {
    background-position: 7.94922% 0
}

.flags--barbados, .flags--brb {
    background-position: 8.3676% 0
}

.flags--basque {
    background-position: 8.78598% 0
}

.flags--belarus, .flags--blr {
    background-position: 9.20436% 0
}

.flags--bel, .flags--belgium {
    background-position: 9.62274% 0
}

.flags--belize, .flags--blz {
    background-position: 10.04112% 0
}

.flags--ben, .flags--benin {
    background-position: 10.4595% 0
}

.flags--bermuda, .flags--bmu {
    background-position: 10.87788% 0
}

.flags--bhutan, .flags--btn {
    background-position: 11.29626% 0
}

.flags--bol, .flags--bolivia {
    background-position: 11.71464% 0
}

.flags--bih, .flags--bosnia-herzegovina {
    background-position: 12.13302% 0
}

.flags--botswana, .flags--bwa {
    background-position: 12.5514% 0
}

.flags--bra, .flags--brazil {
    background-position: 12.96978% 0
}

.flags--british-virgin-islands, .flags--vgb {
    background-position: 13.38816% 0
}

.flags--brn, .flags--brunei {
    background-position: 13.80654% 0
}

.flags--bgr, .flags--bulgaria {
    background-position: 14.22492% 0
}

.flags--bfa, .flags--burkina-faso {
    background-position: 14.6433% 0
}

.flags--bur, .flags--burma {
    background-position: 15.06168% 0
}

.flags--bdi, .flags--burundi {
    background-position: 15.48006% 0
}

.flags--cambodia, .flags--khm {
    background-position: 15.89844% 0
}

.flags--cameroon, .flags--cmr {
    background-position: 16.31682% 0
}

.flags--can, .flags--canada {
    background-position: 16.7352% 0
}

.flags--cape-verde {
    background-position: 17.15358% 0
}

.flags--catalonia {
    background-position: 17.57196% 0
}

.flags--cayman-islands, .flags--cym {
    background-position: 17.99034% 0
}

.flags--caf, .flags--central-african-rep {
    background-position: 18.40872% 0
}

.flags--chad, .flags--tcd {
    background-position: 18.8271% 0
}

.flags--chile, .flags--chl {
    background-position: 19.24548% 0
}

.flags--china, .flags--chn {
    background-position: 19.66386% 0
}

.flags--christmas-island, .flags--cxr {
    background-position: 20.08224% 0
}

.flags--col, .flags--colombia {
    background-position: 20.50062% 0
}

.flags--com, .flags--comoros {
    background-position: 20.919% 0
}

.flags--cok, .flags--cook-islands {
    background-position: 21.75576% 0
}

.flags--costa-rica, .flags--cri {
    background-position: 22.17414% 0
}

.flags--cro, .flags--croatia, .flags--hrv {
    background-position: 22.59252% 0
}

.flags--cub, .flags--cuba {
    background-position: 23.0109% 0
}

.flags--curacao, .flags--cuw {
    background-position: 23.42928% 0
}

.flags--cyp, .flags--cyprus {
    background-position: 23.84766% 0
}

.flags--cze, .flags--czech-republic {
    background-position: 24.26604% 0
}

.flags--denmark, .flags--denmark-amateur, .flags--dnk {
    background-position: 24.68442% 0
}

.flags--dji, .flags--djibouti {
    background-position: 25.1028% 0
}

.flags--dma, .flags--dominica {
    background-position: 25.52118% 0
}

.flags--dom, .flags--dominican-rep {
    background-position: 25.93956% 0
}

.flags--cod, .flags--dr-congo {
    background-position: 26.35794% 0
}

.flags--cog, .flags--congo, .flags--congo-republic, .flags--republic-of-congo {
    background-position: 21.33738% 0
}

.flags--east-timor, .flags--tmp {
    background-position: 26.77632% 0
}

.flags--ecu, .flags--ecuador {
    background-position: 27.1947% 0
}

.flags--ecuatorial-guinea, .flags--gnq {
    background-position: 27.61308% 0
}

.flags--egy, .flags--egypt {
    background-position: 28.03146% 0
}

.flags--el-salvador, .flags--slv {
    background-position: 28.44984% 0
}

.flags--eng, .flags--england, .flags--england-amateur {
    background-position: 28.86822% 0
}

.flags--equatorial-guinea, .flags--gnq {
    background-position: 29.2866% 0
}

.flags--eri, .flags--eritrea {
    background-position: 29.70498% 0
}

.flags--est, .flags--estonia {
    background-position: 30.12336% 0
}

.flags--eth, .flags--ethiopia {
    background-position: 30.54174% 0
}

.flags--faroe-islands, .flags--fro {
    background-position: 30.96012% 0
}

.flags--fiji, .flags--fji {
    background-position: 31.3785% 0
}

.flags--fin, .flags--finland {
    background-position: 31.79688% 0
}

.flags--flag-country-unknown {
    background-position: 32.21526% 0
}

.flags--former-east-timor {
    background-position: 32.63364% 0
}

.flags--fra, .flags--france {
    background-position: 33.05202% 0
}

.flags--french-guiana, .flags--french-guyana, .flags--guf {
    background-position: 33.4704% 0
}

.flags--gab, .flags--gabon {
    background-position: 33.88878% 0
}

.flags--gambia, .flags--gmb {
    background-position: 34.30716% 0
}

.flags--geo, .flags--georgia {
    background-position: 34.72554% 0
}

.flags--deu, .flags--germany, .flags--germany-amateur {
    background-position: 35.14392% 0
}

.flags--gha, .flags--ghana {
    background-position: 35.5623% 0
}

.flags--gib, .flags--gibraltar {
    background-position: 35.98068% 0
}

.flags--granada, .flags--grd {
    background-position: 36.39906% 0
}

.flags--gbr, .flags--great-britain, .flags--uk, .flags--united-kingdom {
    background-position: 36.81744% 0
}

.flags--grc, .flags--greece {
    background-position: 37.23582% 0
}

.flags--greenland, .flags--grl {
    background-position: 37.6542% 0
}

.flags--grd, .flags--grenada {
    background-position: 38.07258% 0
}

.flags--glp, .flags--guadeloupe {
    background-position: 38.49096% 0
}

.flags--guam, .flags--gum {
    background-position: 38.90934% 0
}

.flags--gtm, .flags--guatemala {
    background-position: 39.32772% 0
}

.flags--gin, .flags--guinea {
    background-position: 39.7461% 0
}

.flags--gnb, .flags--guinea-bissau {
    background-position: 40.16448% 0
}

.flags--guy, .flags--guyana {
    background-position: 40.58286% 0
}

.flags--haiti, .flags--hti {
    background-position: 41.00124% 0
}

.flags--hnd, .flags--honduras {
    background-position: 41.41962% 0
}

.flags--hkg, .flags--hong-kong {
    background-position: 41.838% 0
}

.flags--hun, .flags--hungary {
    background-position: 42.25638% 0
}

.flags--iceland, .flags--isl {
    background-position: 42.67476% 0
}

.flags--ind, .flags--india {
    background-position: 43.09314% 0
}

.flags--idn, .flags--indonesia {
    background-position: 43.51152% 0
}

.flags--international {
    background-position: 43.9299% 0
}

.flags--iran, .flags--irn {
    background-position: 44.34828% 0
}

.flags--iraq, .flags--irq {
    background-position: 44.76666% 0
}

.flags--ireland, .flags--irl {
    background-position: 45.18504% 0
}

.flags--isr, .flags--israel {
    background-position: 45.60342% 0
}

.flags--ita, .flags--italy {
    background-position: 46.0218% 0
}

.flags--civ, .flags--ivory-coast {
    background-position: 46.44018% 0
}

.flags--jam, .flags--jamaica {
    background-position: 46.85856% 0
}

.flags--japan, .flags--jpn {
    background-position: 47.27694% 0
}

.flags--jersey, .flags--jey {
    background-position: 47.69532% 0
}

.flags--jor, .flags--jordan {
    background-position: 48.1137% 0
}

.flags--kaz, .flags--kazakhstan {
    background-position: 48.53208% 0
}

.flags--ken, .flags--kenya {
    background-position: 48.95046% 0
}

.flags--kir, .flags--kiribati {
    background-position: 49.36884% 0
}

.flags--kos, .flags--kosovo {
    background-position: 49.78722% 0
}

.flags--ala, .flags--aland-islands {
    background-position: 50.2056% 0
}

.flags--kgz, .flags--kyrgyzstan {
    background-position: 50.62398% 0
}

.flags--laos {
    background-position: 51.04236% 0
}

.flags--latvia, .flags--lva {
    background-position: 51.46074% 0
}

.flags--lbn, .flags--lebanon {
    background-position: 51.87912% 0
}

.flags--lesotho, .flags--lso {
    background-position: 52.2975% 0
}

.flags--lbr, .flags--liberia {
    background-position: 52.71588% 0
}

.flags--lby, .flags--libya {
    background-position: 53.13426% 0
}

.flags--lie, .flags--liechtenstein {
    background-position: 53.55264% 0
}

.flags--lithuania, .flags--ltu {
    background-position: 53.97102% 0
}

.flags--lux, .flags--luxembourg {
    background-position: 54.3894% 0
}

.flags--mac, .flags--macao {
    background-position: 54.80778% 0
}

.flags--macedonia, .flags--mkd {
    background-position: 55.22616% 0
}

.flags--madagascar, .flags--mdg {
    background-position: 55.64454% 0
}

.flags--malawi, .flags--mwi {
    background-position: 56.06292% 0
}

.flags--malaysia, .flags--mys {
    background-position: 56.4813% 0
}

.flags--maldives, .flags--mdv {
    background-position: 56.89968% 0
}

.flags--mali, .flags--mli {
    background-position: 57.31806% 0
}

.flags--malta, .flags--mlt {
    background-position: 57.73644% 0
}

.flags--marshall-islands, .flags--mhl {
    background-position: 58.15482% 0
}

.flags--martinique, .flags--mtq {
    background-position: 58.5732% 0
}

.flags--mauritania, .flags--mrt {
    background-position: 58.99158% 0
}

.flags--mauritius, .flags--mus {
    background-position: 59.40996% 0
}

.flags--mex, .flags--mexico {
    background-position: 59.82834% 0
}

.flags--fsm, .flags--micronesia {
    background-position: 60.24672% 0
}

.flags--mda, .flags--moldova {
    background-position: 60.6651% 0
}

.flags--mco, .flags--monaco {
    background-position: 61.08348% 0
}

.flags--mng, .flags--mongolia {
    background-position: 61.50186% 0
}

.flags--mne, .flags--montenegro {
    background-position: 61.92024% 0
}

.flags--montserrat, .flags--msr {
    background-position: 62.33862% 0
}

.flags--mar, .flags--morocco {
    background-position: 62.757% 0
}

.flags--moz, .flags--mozambique {
    background-position: 63.17538% 0
}

.flags--mmr, .flags--myanmar {
    background-position: 63.59376% 0
}

.flags--n-ireland, .flags--nir, .flags--northern-ireland {
    background-position: 64.01214% 0
}

.flags--nam, .flags--namibia {
    background-position: 64.43052% 0
}

.flags--nauru, .flags--nru {
    background-position: 64.8489% 0
}

.flags--nepal, .flags--npl {
    background-position: 65.26728% 0
}

.flags--netherlands, .flags--nld {
    background-position: 65.68566% 0
}

.flags--ant, .flags--netherlands-antilles {
    background-position: 66.10404% 0
}

.flags--ncl, .flags--new-caledonia {
    background-position: 66.52242% 0
}

.flags--new-zealand, .flags--nzl {
    background-position: 66.9408% 0
}

.flags--nic, .flags--nicaragua {
    background-position: 67.35918% 0
}

.flags--ner, .flags--niger {
    background-position: 67.77756% 0
}

.flags--nga, .flags--nigeria {
    background-position: 68.19594% 0
}

.flags--north-korea, .flags--prk {
    background-position: 68.61432% 0
}

.flags--mnp, .flags--northern-mariana-islands {
    background-position: 69.0327% 0
}

.flags--nor, .flags--norway {
    background-position: 69.45108% 0
}

.flags--oman, .flags--omn {
    background-position: 69.86946% 0
}

.flags--pak, .flags--pakistan {
    background-position: 70.28784% 0
}

.flags--palau, .flags--plw {
    background-position: 70.70622% 0
}

.flags--palestine, .flags--pse {
    background-position: 71.1246% 0
}

.flags--pan, .flags--panama {
    background-position: 71.54298% 0
}

.flags--papua-new-guinea, .flags--png {
    background-position: 71.96136% 0
}

.flags--paraguay, .flags--pry {
    background-position: 72.37974% 0
}

.flags--per, .flags--peru {
    background-position: 72.79812% 0
}

.flags--philippines, .flags--phl {
    background-position: 73.2165% 0
}

.flags--pol, .flags--poland {
    background-position: 73.63488% 0
}

.flags--portugal, .flags--prt {
    background-position: 74.05326% 0
}

.flags--pri, .flags--puerto-rico {
    background-position: 74.47164% 0
}

.flags--qat, .flags--qatar {
    background-position: 74.89002% 0
}

.flags--romania, .flags--rou {
    background-position: 75.3084% 0
}

.flags--rus, .flags--russia, .flags--russian-federation {
    background-position: 75.72678% 0
}

.flags--rwa, .flags--rwanda {
    background-position: 76.14516% 0
}

.flags--lca, .flags--saint-lucia {
    background-position: 76.56354% 0
}

.flags--saint-vincent-and-the-grenadines, .flags--vct {
    background-position: 76.98192% 0
}

.flags--samoa, .flags--wsm {
    background-position: 77.4003% 0
}

.flags--san-marino, .flags--smr {
    background-position: 77.81868% 0
}

.flags--sao-tome-and-principe, .flags--saotomeandprincipe, .flags--stp {
    background-position: 78.23706% 0
}

.flags--sau, .flags--saudi-arabia, .flags--saudiarabia {
    background-position: 78.65544% 0
}

.flags--sco, .flags--scotland {
    background-position: 79.07382% 0
}

.flags--sen, .flags--senegal {
    background-position: 79.4922% 0
}

.flags--serbia, .flags--srb {
    background-position: 79.91058% 0
}

.flags--seychelles, .flags--syc {
    background-position: 80.32896% 0
}

.flags--sierra-leone, .flags--sle {
    background-position: 80.74734% 0
}

.flags--sgp, .flags--singapore {
    background-position: 81.16572% 0
}

.flags--slovakia, .flags--svk {
    background-position: 81.5841% 0
}

.flags--slovenia, .flags--svn {
    background-position: 82.00248% 0
}

.flags--slb, .flags--solomon-islands {
    background-position: 82.42086% 0
}

.flags--som, .flags--somalia {
    background-position: 82.83924% 0
}

.flags--south-africa, .flags--zaf {
    background-position: 83.25762% 0
}

.flags--kor, .flags--south-korea {
    background-position: 83.676% 0
}

.flags--south-sudan, .flags--ssd {
    background-position: 84.09438% 0
}

.flags--esp, .flags--spain, .flags--spain-amateur {
    background-position: 84.51276% 0
}

.flags--lka, .flags--sri-lanka {
    background-position: 84.93114% 0
}

.flags--kna, .flags--st-kitts-and-nevis {
    background-position: 85.34952% 0
}

.flags--sdn, .flags--sudan {
    background-position: 85.7679% 0
}

.flags--sur, .flags--surinam, .flags--suriname {
    background-position: 86.18628% 0
}

.flags--swaziland, .flags--swz {
    background-position: 86.60466% 0
}

.flags--swe, .flags--sweden, .flags--sweden-amateur {
    background-position: 87.02304% 0
}

.flags--che, .flags--switzerland {
    background-position: 87.44142% 0
}

.flags--sye, .flags--syria {
    background-position: 87.8598% 0
}

.flags--tahiti {
    background-position: 88.27818% 0
}

.flags--chinese-taipei, .flags--taiwan, .flags--twn {
    background-position: 88.69656% 0
}

.flags--tajikistan, .flags--tjk {
    background-position: 89.11494% 0
}

.flags--tanzania, .flags--tza {
    background-position: 89.53332% 0
}

.flags--tha, .flags--thailand {
    background-position: 89.9517% 0
}

.flags--timor-leste, .flags--tls {
    background-position: 90.37008% 0
}

.flags--tgo, .flags--togo {
    background-position: 90.78846% 0
}

.flags--ton, .flags--tonga {
    background-position: 91.20684% 0
}

.flags--trinidad-and-tobago, .flags--tto {
    background-position: 91.62522% 0
}

.flags--tun, .flags--tunisia {
    background-position: 92.0436% 0
}

.flags--tur, .flags--turkey, .flags--turkey-amateur {
    background-position: 92.46198% 0
}

.flags--tkm, .flags--turkmenistan {
    background-position: 92.88036% 0
}

.flags--tca, .flags--turks-and-caicos-islands {
    background-position: 93.29874% 0
}

.flags--tuv, .flags--tuvalu {
    background-position: 93.71712% 0
}

.flags--are, .flags--uae, .flags--united-arab-emirates {
    background-position: 94.1355% 0
}

.flags--uga, .flags--uganda {
    background-position: 94.55388% 0
}

.flags--ukr, .flags--ukraine {
    background-position: 94.97226% 0
}

.flags--uruguay, .flags--ury {
    background-position: 95.39064% 0
}

.flags--usa {
    background-position: 95.80902% 0
}

.flags--uzb, .flags--uzbekistan {
    background-position: 96.2274% 0
}

.flags--vanuatu, .flags--vut {
    background-position: 96.64578% 0
}

.flags--ven, .flags--venezuela {
    background-position: 97.06416% 0
}

.flags--vietnam, .flags--vnm {
    background-position: 97.48254% 0
}

.flags--virgin-islands {
    background-position: 97.90092% 0
}

.flags--wal, .flags--wales {
    background-position: 98.3193% 0
}

.flags--yem, .flags--yemen {
    background-position: 98.73768% 0
}

.flags--zambia, .flags--zmb {
    background-position: 99.15606% 0
}

.flags--eaz, .flags--zanzibar {
    background-position: 99.57444% 0
}

.flags--zimbabwe, .flags--zwe {
    background-position: 99.99282% 0
}

.flags--africa {
    background: url("/images/africa@2x.png") 0 0/contain no-repeat
}

@media (-o-min-device-pixel-ratio: 3/2),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx) {
    .flags--africa {
        background: url("/images/africa@3x.png") 0 0/contain no-repeat
    }
}

.flags--asia, .flags--asian-games {
    background: url("/images/asia@2x.png") 0 0/contain no-repeat
}

@media (-o-min-device-pixel-ratio: 3/2),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx) {
    .flags--asia, .flags--asian-games {
        background: url("https://www.sofascore.com/bundles/sofascoreweb/images/regions/asia@3x.png") 0 0/contain no-repeat
    }
}

.flags--europe {
    background: url("/images/europe@2x.png") 0 0/contain no-repeat
}

@media (-o-min-device-pixel-ratio: 3/2),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx) {
    .flags--europe {
        background: url("https://www.sofascore.com/bundles/sofascoreweb/images/regions/europe@3x.png") 0 0/contain no-repeat
    }
}

.flags--caribbean, .flags--north-america, .flags--north_america, .flags--pan-american-games {
    background: url("https://www.sofascore.com/bundles/sofascoreweb/images/regions/north_america@2x.png") 0 0/contain no-repeat
}

@media (-o-min-device-pixel-ratio: 3/2),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx) {
    .flags--caribbean, .flags--north-america, .flags--north_america, .flags--pan-american-games {
        background: url("https://www.sofascore.com/bundles/sofascoreweb/images/regions/north_america@3x.png") 0 0/contain no-repeat
    }
}

.flags--north-central-america, .flags--north_central_america {
    background: url("https://www.sofascore.com/bundles/sofascoreweb/images/regions/north_central_america@2x.png") 0 0/contain no-repeat
}

@media (-o-min-device-pixel-ratio: 3/2),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx) {
    .flags--north-central-america, .flags--north_central_america {
        background: url("https://www.sofascore.com/bundles/sofascoreweb/images/regions/north_central_america@3x.png") 0 0/contain no-repeat
    }
}

.flags--south-america, .flags--south_america {
    background: url("/images/south_america@2x.png") 0 0/contain no-repeat
}

@media (-o-min-device-pixel-ratio: 3/2),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx) {
    .flags--south-america, .flags--south_america {
        background: url("https://www.sofascore.com/bundles/sofascoreweb/images/regions/south_america@3x.png") 0 0/contain no-repeat
    }
}

.flags--international, .flags--wch-ibaf, .flags--world {
    background: url("/images/international@2x.png") 0 0/contain no-repeat
}

@media (-o-min-device-pixel-ratio: 3/2),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx) {
    .flags--international, .flags--wch-ibaf, .flags--world {
        background: url("https://www.sofascore.com/bundles/sofascoreweb/images/regions/international@3x.png") 0 0/contain no-repeat
    }
}

.flags--oceania {
    background: url("https://www.sofascore.com/bundles/sofascoreweb/images/regions/oceania@2x.png") 0 0/contain no-repeat
}

@media (-o-min-device-pixel-ratio: 3/2),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx) {
    .flags--oceania {
        background: url("https://www.sofascore.com/bundles/sofascoreweb/images/regions/oceania@3x.png") 0 0/contain no-repeat
    }
}

.flags-tournament-color {
    background-image: url("https://www.sofascore.com/bundles/sofascoreweb/images/sprites/sprite-tournament-color.png")
}

@media (-o-min-device-pixel-ratio: 3/2),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx) {
    .flags-tournament-color {
        background-image: url("https://www.sofascore.com/bundles/sofascoreweb/images/sprites/sprite-tournament-color-retina.png");
        background-size: 420px 28px
    }
}
